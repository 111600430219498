import React from "react";

import { Seo } from "../components/seo";

export default function NotFoundPage() {
  return (
    <>
      <Seo title="Error 404" />

      <main>
        <p>Error 404</p>
      </main>
    </>
  );
}
